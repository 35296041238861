<template>
  <div>
    <div class="carta_fondo">
      <img src="../assets/CARTA-SABORES.png" class="image_carta_mobile">
      <img src="../assets/CARTA-SABORES.png" class="image_carta_desktop">
    </div>
    <!--  <div class="carta_fondo">-->
    <!--    <img src="https://quickeat.s3.us-west-2.amazonaws.com/local/Donde+Walter/new_web_files/carta_web_dw_2.webp" class="image_carta_mobile">-->
    <!--    <img src="https://quickeat.s3.us-west-2.amazonaws.com/local/Donde+Walter/new_web_files/carta_web_dw_2.webp" class="image_carta_desktop">-->
    <!--  </div>-->

  </div>
</template>

<script>

export default {
  name: "Sabores",

  components: {
  },
  data () {
    return {
    }
  },
}
</script>

<style scoped>
.carta_fondo {
  width: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.carta_fondo .image_carta_mobile {
  width: 100%;
  display: flex;
}
.carta_fondo .image_carta_desktop {
  display: none;
}
@media only screen and (min-width: 768px) {
  .carta_fondo .image_carta_desktop {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }
  .carta_fondo .image_carta_mobile {
    display: none;
  }
}

@media only screen and (min-width: 1920px) {
}
</style>